import { socialData } from './data/socials';
import { Flex, Icon, Link } from '@chakra-ui/react';
import { FaGithub, FaTwitter, FaRegFileCode } from 'react-icons/fa';

const Footer = () => {
  return (
    <Flex as="footer" align="center" justifyContent={"center"} direction="row" position={"sticky"} top={"[100vh]"} marginTop={"90px"} p={9} color="gray.500" fontWeight="medium" gap={2} >
      <Link href={socialData.TWITTER} target="_blank" style={{ opacity: '75', hover: 'opacity-100' }} _hover={{ textDecoration: 'none' }}>
        <FaTwitter/>
      </Link>
      <Link href={socialData.ARBLET_GITHUB} target="_blank" style={{ opacity: '75', hover: 'opacity-100' }} _hover={{ textDecoration: 'none' }}>
        <FaGithub/>
      </Link>
      <Link href={socialData.ARBLET_CONTRACT_ADDRESS} target="_blank" style={{ opacity: '75', hover: 'opacity-100' }} _hover={{ textDecoration: 'none'}}>
        <FaRegFileCode/>
      </Link>
      <Link href={socialData.PXSWAP} target="_blank" style={{ opacity: '75', hover: 'opacity-100' }} _hover={{ textDecoration: 'none' }}>
        ✨
      </Link>
{/*       <Text as="a" href={socialData.FLATICON} target="_blank" style={{ fontSize: 'x-small', fontWeight: 'light', opacity: '75', hover: 'opacity-100' }} _hover={{ textDecoration: 'none' }}>
        Logo by Freepik - Flaticon
      </Text> */}
    </Flex>
  );
};

export default Footer;
