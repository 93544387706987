import { useContractRead } from "wagmi";
import { ABI, CONTRACT_ADDRESS } from './data/abi';

const Pool = () => {

    const { data, isError, isLoading } = useContractRead({
        address: CONTRACT_ADDRESS,
        abi: ABI,
        functionName: 'currentLiquidity',
        onSuccess(data) {
            console.log('Success currentLiquidity', data/10**18)
        },
        
      })

    return (
      <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          textAlign: 'center', 
          gap: '4px', 
          margin: '4px' }}>
          <h1 style={{
              webkitBackgroundClip: 'text',
              fontSize: '3rem',
              fontWeight: 'bold',
              marginBottom: '6px'}}>
               Ξ
                {isError ? 'Error' : isLoading ? 'Loading...' : data/10**18}
          </h1>
      </div>
    )
  }
  
  export default Pool