export const socialData = {
  EMAIL: 'alikonuk1@protonmail.com',
  TWITTER: 'https://twitter.com/alikonukk1',
  PXSWAP: 'https://pxswap.xyz',
  GITHUB: 'https://github.com/alikonuk1',
  ARBLET_GITHUB: 'https://github.com/alikonuk1/arblet',
  ARBLET_CONTRACT_ADDRESS:
    'https://goerli.etherscan.io/address/0x1b11E14C93505e849F54c9a778BBA16b293A342B#code',
  FLATICON: 'https://www.flaticon.com/free-icons/ethereum',
}
