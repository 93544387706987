import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
  extendTheme
} from '@chakra-ui/react';

import Header from './Header';
import Hero from './Hero';
import Pool from './Pool';
import UserCard from './UserCard';
import Footer from './Footer';

const modifiedTheme = extendTheme({
  styles: {
    global: {
      body: {
        mode:{
          dark:{
            backgroundColor: "black",
          }
        }
      },
    },
  },
});

function App() {

  return (
    <ChakraProvider theme={modifiedTheme}>
      <Header/>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="60vh" p={3} >
          <VStack spacing={8}>
            <div style={{ 
              display: 'flex', 
              flexDirection: 'row', 
              alignItems: 'center', 
              justifyContent: 'center', 
              height: 'full', 
              width: 'full', 
              gap: '12px', 
              paddingBottom: '12px',
              paddingTop: "70px",
              '@media (minWidth: 640px)': {
                flexDirection: 'column'
              }}}>
              <div style={{ width: 'full', maxWidth: '640px' }}>
                <VStack spacing={8}>
                  <Hero />
                </VStack>
{/*               <div className='w-full lg:w-[450px]'> */}
                <VStack spacing={8}>
                  <Pool />
                </VStack>
{/*               </div> */}
              </div>
                <VStack spacing={8}>
                  <UserCard />
                </VStack>
            </div>
          </VStack>
        </Grid>
      </Box>
      <Footer/>
    </ChakraProvider>
  );
}

export default App;
