import { useMemo } from 'react'
import { useEnsAvatar } from 'wagmi'
import { emojiAvatarForAddress } from './emojiAvatarForAddress'

const Avatar = ({ address, size, squircle }) => {
  const { data: ensAvatar } = useEnsAvatar({
    addressOrName: address,
  })
  const { color: backgroundColor, emoji } = useMemo(
    () => emojiAvatarForAddress(address),
    [address]
  )

  return ensAvatar ? (
    <div style={{ display: 'block' }}>
      <div
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: size,
          width: size,
          borderRadius: squircle ? "36%" : "none",
          }}
      />
    </div>
  ) : (
    <div style={{ display: 'block' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: size,
          width: size,
          borderRadius: squircle ? "36%" : "none",
          backgroundColor: !ensAvatar && backgroundColor,
          fontSize: size / 2,
        }}
      >
        {emoji}
      </div>
    </div>
  )
}

export default Avatar
