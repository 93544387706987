import { useState } from 'react'
import { ethers } from 'ethers'
import {
    Card,
    CardBody, 
    Heading, 
    VStack,
    NumberInput,
    NumberInputField,
    InputRightElement,
    InputGroup,
    Stack,
    Flex
} from '@chakra-ui/react'
import { useAccount } from "wagmi";
import Avatar from './Avatar'
import Shares from './Shares';
import Provide from './components/Provide';
import Withdraw from './components/Withdraw';

const UserCard = () => {

    const { address } = useAccount()

    const [amount, setAmount] = useState('')

    const amountInput = (e) => {
        const amount = (e.target.value);
        setAmount(ethers.utils.parseEther(amount));
      };

    return (
    <Card align='center' borderRadius="3xl" boxShadow={"2xl"}>
        <CardBody>
            <VStack spacing={6}>
                <Avatar address={address} size={100} squircle={true} />
                <Heading size='md'> Your Shares</Heading>
                <Shares />
            </VStack>
            <VStack spacing={6}>
            <Stack spacing={4}>
                <InputGroup >
                    <InputRightElement
                        pointerEvents='none'
                        color='gray.300'
                        fontSize='1.2em'
                        children='Ξ'
                    />
                <NumberInput min={0.5}>
                    <NumberInputField 
                        borderRadius={"12px"}
                        id="amount"
                        onChange={amountInput}
                        placeholder="0.6"
/*                         value={amount && !isNaN(amount) && amount > 0 ? (amount) : ''} */
                    />
                </NumberInput>
                </InputGroup>
                </Stack>
                <Flex>
                <Provide amount={amount}/>
                <Withdraw amount={amount}/>
                </Flex>
            </VStack>
        </CardBody>
    </Card>
    )
}

export default UserCard